<template>
    <div id="invoice-container" style="max-width: 930px; margin: auto">
      <div id="user_data">
        <div class="vx-row">
          <div v-for="order in orders" :key="order.id" class="vx-col  w-full">
            <vx-card :title="'Order #'+order.id " class="mb-base">
              <div class="vx-row">
                  <div class="vx-col lg:w-1/3 w-full  mb-3">
                      <h5>Order Detail</h5>
                    <ul class="mt-6">
                      <li class="flex mb-3">
                        <span class="ml-2 inline-block font-semibold">Date</span>
                        <span class="mx-2">-</span>
                        <span class="mr-4">{{order.created_at | formatDate}}</span>
                      </li>
                      <li class="flex mb-3">
                        <span class="ml-2 inline-block font-semibold">Status</span>
                        <span class="mx-2">-</span>
                        <span class="mr-4">{{status[order.status]}}</span>
                      </li>
                      <li class="flex mb-3">
                        <span class="ml-2 inline-block font-semibold">Payment method</span>
                        <span class="mx-2">-</span>
                        <span class="mr-4">{{order.payment_method}}</span>
                      </li>
                    </ul>
                  </div>
                <div class="vx-col lg:w-1/3 w-full  mb-3">
                  <h5>Shipping Detail</h5>
                  <ul class="mt-6">
                    <li class="flex mb-3">
                      <span class="ml-2 inline-block font-semibold">Shipping Type</span>
                      <span class="mx-2">-</span>
                      <span class="mr-4">{{order.shipping_method}}</span>
                    </li>
                  </ul>
                  <div class="invoice__recipient-info my-4">
                    <p>{{ order.shipping_first_name }}</p>
                    <p>{{ order.shipping_address_1 }}</p>
                  </div>
                  <div class="invoice__recipient-contact ">
                    <p v-if="order.customer_email" class="flex items-center">
                      <feather-icon icon="MailIcon" svgClasses="h-4 w-4"></feather-icon>
                      <span class="ml-2">{{ order.customer_email }}</span>
                    </p>
                    <p class="flex items-center">
                      <feather-icon icon="PhoneIcon" svgClasses="h-4 w-4"></feather-icon>
                      <span class="ml-2">{{ order.customer_phone }}</span>
                    </p>
                  </div>
                </div>
                <div class="vx-col lg:w-1/3 hidden lg:block w-full  mb-3">
                  <ul class="mt-6">
                    <li class="flex mb-3">
                      <span class="ml-2 inline-block font-semibold">Subtotal</span>
                      <span class="mx-2">-</span>
                      <span class="mr-4">{{order.sub_total.amount |currency}}</span>
                    </li>
                    <li class="flex mb-3">
                      <span class="ml-2 inline-block font-semibold">Shipping</span>
                      <span class="mx-2">-</span>
                      <span class="mr-4">{{order.shipping_cost.amount |currency}}</span>
                    </li>
                    <li v-if="order.discount.amount" class="flex mb-3">
                      <span class="ml-2 inline-block font-semibold">Discount</span>
                      <span class="mx-2">-</span>
                      <span class="mr-4">{{order.discount.amount |currency}}</span>
                    </li>
                    <li class="flex mb-3">
                      <span class="ml-2 inline-block font-semibold">Total</span>
                      <span class="mx-2">-</span>
                      <span class="mr-4">{{order.total.amount |currency}}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div v-for="(item, index) in order.products" :key="index" class="vx-row">
                <vs-divider></vs-divider>
                <div class="vx-col flex lg:w-3/5 w-full px-3 cart-dropdown  mb-3">
                  <div class="vx-col w-1/5 item-img-container bg-white cursor-pointer justify-center" @click="$router.push({name: 'product_view', params: {slug: item.slug }}).catch(() => {})">
                    <img :src="base_image(item.product).path" alt="item" class="cart-dropdown-item-img h-24">
                  </div>

                  <!-- INFO COL -->
                  <div class="vx-col w-4/5 pr-4 pl-2 py-4 flex flex-col justify-center">
                    <div class="flex justify-between">
                      <span @click="$router.push({name: 'product_view', params: {slug: item.product.slug }}).catch(() => {})" class="font-medium block cursor-pointer cart-item-title truncate">{{ item.product.name }}</span>
                    </div>
                    <small class="truncate mb-2">{{ item.product.short_description }}</small>
                    <ul>
                      <p v-for="(op,i) in item.options" :key="i" class="flex">
                        <span class="ml-2 inline-block font-semibold">{{op.option.name}}</span>
                        <span class="mx-2">:</span>
                        <span class="mr-4">{{op.value}}</span>
                      </p>
                    </ul>
                  </div>
                </div>
                <div class="vx-col justify-center my-auto lg:w-1/5 w-full px-3">
                  <div class="flex justify-between">
                    <div >
                      <span>{{item.qty}}</span><span class="text-sm font-medium"><small>x</small> {{item.unit_price.amount | currency}}</span>
                    </div>
                    <span>{{item.line_total.amount | currency}}</span>
                  </div>
                </div>
                <div class="vx-col justify-center my-auto lg:w-1/5 w-full px-3">
                  <vs-button v-if="!item.reviewed == '1'" @click="addReview(item)" type="border">Add review</vs-button>
                </div>
              </div>
              <div class="flex justify-end">
                <vs-button @click="$router.push('/view-order/'+order.id)" type="relief">View Invoice</vs-button>
              </div>
            </vx-card>
          </div>
        </div>
      </div>
      <div class="demo-alignment">
        <vs-popup class="holamundo" title="Add review" :active.sync="openReview">
          <p>Your rating</p>
          <star-rating v-validate="'required'" name="Star" :border-width="4" border-color="#d8d8d8" v-model="reviewData.star" :star-size="20" :rounded-corners="true" :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"></star-rating>
          <p>Comment</p>
          <vs-textarea v-validate="'required'" name="Comment" v-model="reviewData.review" />
          <vs-button @click="submitReview" type="border" class="float-right">Submit</vs-button>
        </vs-popup>
      </div>
    </div>
</template>

<script>
  import StarRating from 'vue-star-rating'
    export default {
        name: "Orders",
      components:{
        StarRating
      },
        data(){
          return {
            orders:[],
            reviewData:{
              orderId:'',
              productId:'',
              review:'',
              star:5
            },
            openReview:false,
            status: {
              'canceled': 'Canceled',
              'completed': 'Completed',
              'on_hold':'On Hold',
              'pending':'Pending',
              'pending_payment':'Pending Payment',
              'processing':'Processing',
              'refunded':'Refunded',
            }

          }
        },
      computed:{
        base_image(){
          return (item) =>  item.files.find(function (f) {
            return f.pivot.zone ==='base_image'
          })
        },
      },
      methods:{
          submitReview(){
            this.$validator.validateAll().then( result =>{
              if (result){
                this.$vs.loading();
                this.axios.post('review', this.reviewData).then(res=>{
                  if (res.status == 200){
                    this.$vs.loading.close();
                    this.$vs.notify({
                      title:res.data.status,
                      text:res.data.message,
                      color:res.data.status == 'success'?'success':'danger'
                    })
                    this.reviewData = {
                      star:5,
                      orderId:'',
                      productId:'',
                      review:''
                    }
                    this.openReview = false;
                  }
                  if (res.status == '402'){
                    this.$vs.notify({
                      title:'Validation error',
                      text:'Fill up all data',
                      color:'danger'
                    })
                  }
                })
                  .catch(error => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                      title:'Request Failed',
                      text:error.message,
                      color:'danger'
                    })
                  })
              }else{
                this.$vs.notify({
                  title:'Validation error',
                  text:'Fill up all data',
                  color:'danger'
                })
              }
            })
          },
          addReview(item){
            this.reviewData.orderId = item.order_id;
            this.reviewData.productId = item.product_id;
            this.openReview = true;
          },
          getOrders(){
            this.$vs.loading();
            this.axios.get('/orders').then(({data})=> {
              this.$vs.loading.close();
              this.orders = data;
            }).catch(error => {
              this.$vs.loading.close();
                this.$vs.notify({
                  tile:'Error',
                  text: error.message,
                  color:'danger',
                })
            });
          }
      },
      mounted(){
          this.getOrders();
      }
    }
</script>

<style  lang="scss" scoped>
  #page-user-view {
    table {
      td {
        vertical-align: top;
        min-width: 140px;
        padding-bottom: .8rem;
        word-break: break-all;
      }

      &:not(.permissions-table) {
        td {
          @media screen and (max-width: 370px) {
            display: block;
          }
        }
      }
    }
  }
</style>
